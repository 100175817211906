<template>
  <header id="header" class="pt-6 pt-md-10 pb-md-">
    <b-container>
      <div class="row align-items-center position-relative mb-10  d-none d-lg-flex">
        <div class="col-auto logo mr-lg-0">

          <b-link to="/">
            <b-img :src="require('@/esf_kerkrade_vitanatura/assets/images/header/logo.png')"
              :srcset="require('!!url-loader?limit=0!@/esf_kerkrade_vitanatura/assets/images/header/logo.png') + ' 253w ,' + require('!!url-loader?limit=0!@/esf_kerkrade_vitanatura/assets/images/header/logo.png') + ' 175w, ' + require('!!url-loader?limit=0!@/esf_kerkrade_vitanatura/assets/images/header/logo.png') + ' 75w '"
              width="320" height="57" alt="Logo">
            </b-img>
          </b-link>

        </div>

        <div class="col-auto header-middle-section">

          <div class="" v-for="topIcon, index in topIcons.slides" :key="index">
            <b-img :src="topIcon.media.url" width="320" height="57" alt="icons"></b-img>
            <span class="text-info">{{ topIcon.title }} </span>
          </div>
        </div>

        <div class="col-auto  d-none d-lg-flex align-items-center justify-content-end search-and-icons ">

          <HeaderSearch />
          <HeaderTopIcons showType="O" />

        </div>

      </div>

    </b-container>

    <!-- Tablet and Mobile Menu -->
    <div class="d-lg-none mobile-menu">
      <div
        class="row align-items-center pt-1 pt-lg-0 position-relative text-capitalize justify-content-center d-lg-none">

        <div class="col-auto  ">
          <Navbar />
        </div>

        <div class="col-auto logo mr-lg-0 w-50">

          <b-link to="/">
            <b-img :src="require('@/esf_kerkrade_vitanatura/assets/images/header/logo.png')"
              :srcset="require('!!url-loader?limit=0!@/esf_kerkrade_vitanatura/assets/images/header/logo.png') + ' 253w ,' + require('!!url-loader?limit=0!@/esf_kerkrade_vitanatura/assets/images/header/logo.png') + ' 175w, ' + require('!!url-loader?limit=0!@/esf_kerkrade_vitanatura/assets/images/header/logo.png') + ' 75w '"
              width="150" height="57" alt="Logo">
            </b-img>
          </b-link>

        </div>

        <div class="col-auto d-flex align-items-center ">
          <HeaderSearch />
          <HeaderTopIcons showType="M" />
        </div>

        <div class="pt-6 w-100 bg-info">
          <VueSlickCarousel :dots="false" :arrows="false">
            <div v-for="topIcon, index in topIcons.slides" :key="index">
              <div class="items d-flex justify-content-center align-items-center">
                <b-img class="mx-2" :src="topIcon.media.url" width="30" height="30" alt="icons"></b-img>
                <span>{{ topIcon.title }} </span>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>

    <div class="bg-info  d-none d-lg-block">
      <div class="d-flex pt-1 pt-lg-0 position-relative text-capitalize justify-content-center">

        <Navbar />

        <div class="d-flex d-lg-none align-items-center justify-content-end search-and-icons pt-6">
          <HeaderSearch />
          <HeaderTopIcons showType="M" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import HeaderSearch from "@/esf_kerkrade_vitanatura/core/components/HeaderSearch";
import HeaderTopIcons from "@/esf_kerkrade_vitanatura/core/components/HeaderTopIcons";
import Navbar from "@/base/core/components/Navbar";
import VueSlickCarousel from 'vue-slick-carousel';
import { isMobile, isTablet, isBrowser } from "mobile-device-detect";



export default {
  name: "Header",
  components: {
    HeaderSearch,
    HeaderTopIcons,
    Navbar,
    VueSlickCarousel
  },
  computed: {
    topIcons() {
      let topHeaderIcons = this.$store.getters["sliders/getSliderByIdentifier"]("top_header_icons");

      if (topHeaderIcons.slides != null) {
        topHeaderIcons.slides.forEach((element) => {
          if (this.mobileCheck == "browser") {
            element.optImage = element.media.url;
          } else if (this.mobileCheck == "tablet") {
            element.optImage = element.media.larger;
          } else {
            element.optImage = element.media.medium;
          }
        });
      }
      return topHeaderIcons;
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
  },
};
</script>